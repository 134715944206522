<template>
  <b-sidebar
    id="create-department-class-program-sidebar"
    hide-footer
    right 
    ref="createRelationSidebar"
    bg-variant="white"
    backdrop

  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Classes to a Department</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Classes Program</span
            >
            <b-form-group class="mb-0">
              <v-select label="name" v-model="classe" placeholder="Select Teacher" :options="classes" :reduce="option => option.id" autoscroll :clearable="true"></v-select>  
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">
              Select Department
            </span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="department" placeholder="Select Department" :options="departments" :reduce="option => option.id" autoscroll :clearable="true"></v-select>  
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          style="margin-top: 400px"
          pill
          @click="submit()"
        >
          Add
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      classe: "",
      department: "",
      classes: [],
      departments: [],
    };
  },
  async mounted() {
    const res_classes = await this.getClassDropDown();
    this.classes = res_classes.data;

    const res_departments = await this.getDepartmentDropDown();
    this.departments = res_departments.data;
  },
  methods: {
    ...mapActions({
      createRelClassesDepartment: "appData/createRelClassesDepartment",
      getClassDropDown: "appData/getClassDropDown",
      getDepartmentDropDown: "appData/getDepartmentDropDown",
    }),


    async submit() {
      try {
        const formData = new FormData();
        formData.append("classes", this.classe);        
        formData.append("department", this.department);        
        formData.append("created_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 
        const res = await this.createRelClassesDepartment(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Relation created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.createRelationSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.classe = "";      
      this.department = "";      
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" })
  },
};
</script>

<style scoped></style>
